import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from '../shared/utils';
import { environment } from '../../../src/environments/environment';
import { throwError } from 'rxjs';
import { LoaderService } from '../shared/genric-service/loader.service';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public isUserLoggedIn: any;

  constructor(
    private http: HttpClient,
    private utils: Utils,
    private loaderService: LoaderService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) {
    this.isUserLoggedIn = false;
  }

  setUserLoggedIn() {
    this.isUserLoggedIn = true;
  }

  getUserLoggedIn() {
    return this.isUserLoggedIn;
  }

  userLogin(body: any, loader: boolean = true): Observable<any> {
    this.loaderService.display(loader);

    var username: any = localStorage.getItem('loginUserName')?.toString() || '';
    var userSchema =
      localStorage.getItem('supportUserServer') == 'LNT'
        ? 'larsentoubro_com'
        : this.utils.getUserSchemaFromUsername(username);

    return this.http
      .post<any>(environment.url + '/token/generate-token', body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-TenantID': body.username,
          'X-Server-Select': userSchema,
        }),
      })
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }

  userLoginWithoutCaptcha(body: any): Observable<any> {
    return this.http
      .post<any>(environment.url + '/token/generate-token', body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-TenantID': body.username,
          'X-Server-Select': this.utils.getUserSchemaFromUsername(
            body.username
          ),
          'X-API-KEY': 'fcEp5I2BOi',
        }),
      })
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }

  SSOLogin(payload: any, username: any): Observable<any> {
    return this.http
      .post<any>(environment.url + '/identity/users/sso-login/', payload, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-TenantID': username,
          'X-Server-Select': this.utils.getUserSchemaFromUsername(username),
        }),
      })
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }

  // FORGOT PASSWORD
  forgotPassword(emailID: any, loader: boolean = true): Observable<any> {
    this.loaderService.display(loader);
    return this.http.get<any>(
      environment.url + '/identity/users/' + emailID + '/forgotPassword',
      {
        headers: new HttpHeaders({
          'X-Server-Select': this.utils.getUserSchemaFromUsername(emailID),
          'X-UI-Version': 'v3',
        }),
      }
    );
  }

  // TWO FACTOR AUTH LOGIN

  userTotpVerification(totpForm: any, loader: boolean = true): Observable<any> {
    this.loaderService.display(loader);
    var username: any = localStorage.getItem('loginUserName')?.toString() || '';
    var userSchema =
      localStorage.getItem('supportUserServer') == 'LNT'
        ? 'larsentoubro_com'
        : this.utils.getUserSchemaFromUsername(username);

    const body = new HttpParams().set('totp', totpForm);

    return this.http
      .post<any>(environment.url + '/verify2fa', body.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'X-Server-Select': userSchema,
        },
      })
      .pipe(
        catchError((e: HttpErrorResponse): any => {
          throwError(this.utils.errorHandler(e));
        })
      );
  }
  getUsers() {
    // const headers = new HttpHeaders({
    //   'Access-control-allow-origin' : '*',
    //   'Accept': `application/json`,
    //   'Content-Type': `application/json`,
    //   'Authorization': `SSWS 007TPL5i3hloxpBq9zOg7UQ992VBW7rYEpa--L6gK3`,
    // });

    // return this.http.get('https://dev-67488984.okta.com/api/v1/users', { headers });
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
      }
    });

    xhr.open('GET', 'https://dev-67488984.okta.com/api/v1/users');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader(
      'Authorization',
      'SSWS 007TPL5i3hloxpBq9zOg7UQ992VBW7rYEpa--L6gK3'
    );
    // WARNING: Cookies will be stripped away by the browser before sending the request.
    // xhr.setRequestHeader("Cookie", "JSESSIONID=3B28675E3290DD4221B895FBEB417B21");

    xhr.send();
  }

  getMicroSoftUsers(token: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get('https://graph.microsoft.com/v1.0/users', { headers });
  }

  async getMe(token: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .get('https://graph.microsoft.com/v1.0/users', { headers })
      .toPromise();
  }

  getImage(token: string, id: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(
      `https://graph.microsoft.com/v1.0/users/${id}/photo/$value`,
      { headers, responseType: 'blob' }
    );
  }
  getGoogleUsers(token: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    return this.http.get(
      `https://www.googleapis.com/admin/directory/v1/users`,
      { headers }
    );
  }
}
